.card-borders {
    border-color: rgba(101, 133, 167, 0.145);
    border-width: 1px 2px;
    border-style: groove;
}

.card-gradient {
    /*background-image: linear-gradient(to bottom right, #009ef700, #009ef724, #009ef766);*/
    background-image: radial-gradient(#009ef740, #009ef700);
    /*background-color:rgba(255, 255, 255, 0);*/
    /*background-image: linear-gradient(to bottom right, #ffffff00, #009ef720, #009ef735);*/
}