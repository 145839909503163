
.tabs-header-custom {
    text-align: center;
}
.tabs-header-custom ul {
    display: inline-block;
}

.tabs-header-custom li {
    float: left;
}